@import './scss/common/variables';
body {
  color: $darker-gray;
}
body, button, a, h1,h2,h3,h4,h5,h6, input, textarea{
  font-family: 'Montserrat', sans-serif;
}
a {
  color: $blue;
}
main {
  display: flex;
  justify-content: flex-start;
  .main-content {
    box-sizing: border-box;
    width: calc(100vw - 150px);
  }
}
.overlay{
  background-color: rgba(0,0,0,0.5);
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  .connect-modal {
    box-sizing: border-box;
    position: fixed;
    width: 350px;
    height: 150px;
    background-color: #FFFFFF;
    padding: 25px;
    left: calc(50vw - 125px);
    top: 20vh;
    text-align: center;
    button {
      margin-top: 25px;
      width: 100%;
      height: 40px;
      border:1px solid #999;
      &:focus {
        outline: none;
      }
      &:active {
        border: 1px inset #999;
      }
    }
  }
}

.fwidth-item{
  display: block;
  text-align: left;
  input, select{
    box-sizing: border-box;
    width: 100%;
  }
}

.flex{ display: flex; }
.justify-flex-end { justify-content: flex-end; }
.justify-space-between {justify-content: space-between;}
.flex-align-center { align-items: center; }
.mt-25 {margin-top: 25px;}
.ml-15 {margin-left: 15px;}