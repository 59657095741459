@import '../../../../scss/common/variables';

.data-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  transition: all 0.3s linear;
  overflow: hidden;
}
.grid-item{
  box-sizing: border-box;
  display: block;
  width: 33%;
  padding: 10px; 

  input {
    box-sizing: border-box;
    height: 30px;
    margin-top: 5px;
    padding-left: 15px;
    width: 100%;
  }
}
.fwidth-item {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  textarea{ 
    height: 150px;
    width: 100%;
  }
}