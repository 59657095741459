$darker-gray: #29333f;
$dark-gray: #313d4b;
$gray: #8e8c91;
$light-gray: #7f7f7f;
$lighter-gray: lighten($light-gray, 20);
$white: #fffeff;
$green: #34c692;
$red: #d6425c;
$pink: #db4876;
$yellow: #eb9804;
$blue: #6cbeed;
$card-header: #f4f4f4;
$font-family: "Lato", sans-serif;
$main-red: #ea2f2f;
$purple: #7243a1;

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.p-inline-4 {
  padding-inline: 25px;
}

.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 10px;
}
