@import "../../scss/common/variables";

.button {
  background-color: $gray;
  border: 1px solid $gray;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  font-size: 15px;
  height: 41px;
  overflow: hidden;
  padding: 10px 15px;
  text-decoration: none;
  transition: all 0.3s linear;
  &.delete-button {
    background: transparent;
    border: none;
    width: 45px;
    .delete-icon {
      color: $red;
    }
  }
  &.borderless {
    border: none;
  }
  &:hover:not(.no-hover) {
    background-color: $light-gray;
  }
  &:focus {
    outline: none;
  }
  &:active {
    border: 1px inset $dark-gray;
  }
  &.fullwidth {
    width: 100%;
  }
  &.yellow {
    background-color: $yellow;
    &:hover {
      background-color: darken($yellow, 10);
    }
    &.disabled {
      background-color: lighten($yellow, 20);
    }
  }
  &.green {
    background-color: $green;
    &:hover {
      background-color: darken($green, 10);
    }
    &.disabled {
      $color: mix($green, #999);
      background-color: $color;
      &:hover:not(.no-hover) {
        background-color: $color;
      }
    }
  }
  &.purple {
    background-color: $purple;
    &:hover {
      background-color: darken($purple, 10);
    }
    &.disabled {
      $color: mix($purple, #999);
      background-color: $color;
      &:hover:not(.no-hover) {
        background-color: $color;
      }
    }
  }
  &.pink {
    background-color: $pink;
    &:hover {
      background-color: darken($pink, 10);
    }
    &.disabled {
      $color: mix($pink, #999);
      background-color: $color;
      &:hover:not(.no-hover) {
        background-color: $color;
      }
    }
  }
  &.red {
    background-color: $red;
    &:hover:not(.no-hover) {
      background-color: darken($red, 10);
    }
    &.disabled {
      background-color: lighten($red, 20);
    }
  }
  &.blue {
    background-color: $blue;
    &:hover:not(.no-hover) {
      background-color: darken($blue, 10);
    }
    &.disabled {
      background-color: lighten($blue, 20);
    }
  }
  &.saving {
    background-color: $white !important;
  }
  .ellipsis {
    width: 70px;
    margin-top: -25px;
  }
}
