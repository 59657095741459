@import '../../scss/common/variables';

.panel {
  border-radius: 5px;
  box-shadow: 0 0 3px $light-gray;
  margin: 25px auto;
  overflow: hidden;
  width: 90%;
  .panel-header{
    background-color: $red;
    padding: 15px;
    color: $white;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    h2 {
      margin: 0;
    }
    .reload {
      cursor: pointer;
    }
  }
  .panel-content {
    padding: 15px;
  }
  .panel-footer {
    display: flex;
    justify-content: flex-end;
  }
}