@import '../../scss/common/variables';

#main-header{
  align-items: center;
  background-color: white;
  box-shadow: 0 5px 5px $lighter-gray;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 25px 0 13px;
  .img-container {
    background-color: white;
    border-radius: 5px;
    padding: 10px 10px 5px 10px;
    img {
      width: 100px;
    }
  }
  .user-menu {
    align-items: center;
    border: 2px solid red;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    position: relative;
    transition: border-color 0.5s ease-in-out;
    width: 35px;
    .fa-user-alt{
      color: $red;
      transition: color 0.5s ease-in-out;
    }
    .user-menu-list {
      background: $white;
      box-sizing: border-box;
      color: $white;
      height: 100vh;
      list-style-type: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
      li button{
        background: none;
        border: none;
        box-sizing: border-box;
        color: $blue;
        cursor: pointer;
        display: inline-block;
        height: 45px;
        text-decoration: underline;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
      @media (min-width: 768px){
        box-shadow: 0 0 4px #999;
        height: auto;
        position: absolute;
        text-align: center;
        top: 45px;
        right: -20px; 
        width: 150px;
        li a {
          padding: 15px;
        }
      }
    }

    &:hover{
      border-color: darken($red, 15);
      .fa-user-alt {
        color: darken($red, 15);
      }
    }
  }
}