@import '../../scss/common/variables';
.sidebar {
  background-color: $darker-gray;
  box-sizing: border-box;
  min-height: calc(100vh - 80px);
  width: 150px;
  .sidebar-nav {
    a {
      border-left: 0 solid $red;
      box-sizing: border-box;
      color: white;
      text-decoration: none;
      width: 100%;
      padding: 15px;
      display: inline-block;
      transition: all 0.3s linear; 

      &.active, &:hover{
        color: #ea2f2f;
        padding-left: 10px;
        border-left: 5px solid $red;
      }
    }
  }
}