.variable-row {
  background: white;
  padding-block: 10px;
  padding-inline: 20px;
  //cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.7);
  }
}
.variable-row:nth-child(odd) {
  background: #eaeaea;
}
