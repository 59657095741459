@import '../../../../scss/common/variables';

.section-sistema{
  box-sizing: border-box;
  box-shadow: 0 0 5px $lighter-gray;
  margin-bottom: 15px;
  padding: 15px;
  &.hidden{
    height: 71px;
  }
}